export const RAGE_LOCK_CLASS = 'js-ar-lock';
const RAGE_LOCK_DISABLED_CLASS = 'js-ar-lock-disabled';
const RAGE_LOCK_TIMEOUT = 3000; // 3 seconds


export function setCookie(name, value, days) {
  var expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toGMTString()};`;
  } else {
    expires = '';
  }
  const hostname = location.hostname;
  document.cookie = `${name}=${value};path=/;${expires}domain=.${hostname}`;
}

export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    var c_start = document.cookie.indexOf(c_name + '=');
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      var c_end = document.cookie.indexOf(';', c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
}

export function localizedFormatMoney(value, decimal_places) {
  return value.formatMoney(
    decimal_places,
    '',
    get_format('THOUSAND_SEPARATOR'),
    get_format('DECIMAL_SEPARATOR'),
  );
}

export function push_enhanced_ecommerce_data(
  action,
  product_data,
  action_field,
  action_name,
  non_interaction,
  extra_data,
) {
  if (
    typeof product_data === 'undefined' ||
    typeof window.dataLayer === 'undefined'
  ) {
    return;
  }

  var payload = {
    ecommerce: {},
    event: 'Enhanced_Ecommerce',
    'ee-event-category': 'Enhanced Ecommerce',
    'ee-event-action': action_name,
    'ee-event-non-interaction': non_interaction,
  };
  payload.ecommerce[action] = {
    actionField: action_field,
    products: [product_data],
  };
  if (extra_data) {
    payload.ecommerce[action] = { ...payload.ecommerce[action], ...extra_data };
  }
  window.dataLayer.push(payload);
}

export function isMobileWidth() {
  return $(window).width() < 768;
}

export function inputIntValue($elem) {
  return parseInt($elem.val()) || 0;
}

// Meta marketing api stuff, details available here:
// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc

function fbc() {
  // Check if fbc cookie is present and if it matches the fbclid query parameter.
  // If not, create a new fbc cookie.
  // TLDR: ensures fbc (fbclid) cookie is present and up-to-date.
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let c = getCookie('_fbc');
  let fbclid = urlParams.get('fbclid');

  if (c && (!fbclid || c.split('.').pop() === fbclid)) {
    return c;
  }

  if (!fbclid) {
    return;
  }

  let fbc = `fb.1.${Date.now()}.${fbclid}`;
  setCookie('_fbc', fbc, 90);
  return fbc;
}

function fbp() {
  // Check if fbp cookie is present. If not, create a new fbp cookie.
  // TLDR: ensures fbp (random id) cookie is present and up-to-date.
  let c = getCookie('_fbp');
  if (c) {
    return c;
  }

  let fbp = `fb.1.${Date.now()}.${Math.floor(Math.random() * 9000000000) + 1}`;
  setCookie('_fbp', fbp, 90);
  return fbp;
}

function metaEventsWatcher(debug = false) {
  if (!('Proxy' in window)) {
    console.warn("Your browser doesn't support Proxies.");
    return;
  }
  function sme_send(pixel_id, data) {
    const base_url = __CLOUDFLARE_WORKER_URL__;
    const dest_url = base_url + '/fb/' + pixel_id;
    const body = {
      'encrypted_data': data,
      'event_time': Math.floor(Date.now() / 1000),
      'fbc': fbc(),
      'fbp': fbp(),
    };

    if (debug) {
      console.log('Would send event: ', { dest_url, body });
      console.log('Dest url: ', {dest_url});
      console.log('body: ', {body});
      return;
    };

    fetch(dest_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Origin': __FULL_WEBSITE_URL__,
      },
      body: JSON.stringify(body),
    })
      .then(response => response)
      .catch(error => {
        console.error('Failed to send data to cf worker');
      });
  }

  window.meq = new Proxy(window.meq, {
    set: function(target, property, value, receiver) {
      // target: meq array object holding events
      // property: index of the array element
      // value: the new item being added to the array (consists of pixel_id and data)
      target[property] = value;
      if (property !== 'length') sme_send(value[0], value[1]);
      return true;
    },
  });

  while(window.meq.length) {
    let event = window.meq.shift();
    sme_send(event[0], event[1]);
  }
}
metaEventsWatcher(false);

export function antiRageClick($elem) {
  // Hard-lock the form for some time after submission attempt.
  // Temporary code to check for the source of double form submissions.
  // TODO: Remove after the issue is resolved.

  // Prevents locking $elem if has `RAGE_LOCK_DISABLED_CLASS` class.
  if ($elem.hasClass(RAGE_LOCK_DISABLED_CLASS)) {
    return false;
  }

  if ($elem.hasClass(RAGE_LOCK_CLASS)) {
    return true;
  } else {
    $elem.addClass(RAGE_LOCK_CLASS);
    // auto remove lock after some time
    setTimeout(function() {
      $elem.removeClass(RAGE_LOCK_CLASS);
    }, RAGE_LOCK_TIMEOUT);
    return false;
  }
}
